<template>
  <div>
    <b-card>
      <div class="pr-5 pl-5">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active">
              <div class="mt-2 pt-75">
                <div class="media mb-2">
                  <div class="media-body">
                    <b-row class="">
                      <b-col md="auto">
                        <b-media-body>
                          <!-- +++++++++++ -->
                          <b-avatar
                            badge
                            size="80"
                            badge-variant="info"
                            :src="imageUrl || userInfo.picture"
                            :icon="'image'"
                          >
                            <template #badge>
                              <input
                              accept="image/*"
                                type="file"
                                hidden
                                id="picture"
                                @change="onFileUpload"
                              />
                              <b-icon
                                @click="openFilePicker"
                                style="cursor: pointer"
                                icon="camera"
                              ></b-icon>
                            </template>
                          </b-avatar>
                          <!-- +++++++++++ -->
                        </b-media-body>
                      </b-col>
                      <b-col>
                        <h4 class="">
                          {{ userInfo.firstname }} {{ userInfo.lastname }}
                        </h4>
                        <span> {{ userInfo.email }} </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <b-card
                  bg-variant=""
                  header="Mes Informations"
                 
                >
                  <b-card-text>
                    <b-overlay :show="loading" rounded="sm">
                      <validation-observer ref="updateCollaborator">
                        <form class="">
                          <div class="row">
                            <div class="col-md-4 col-12">
                              <div role="group" class="form-group">
                                <label for="name">Nom</label>
                                <div>
                                  <input
                                    id="name"
                                    type="text"
                                    class="form-control"
                                    v-model="userInfo.firstname"
                                  /><!----><!----><!---->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div role="group" class="form-group">
                                <label for="lastname" class="d-block"
                                  >Prénom(s)</label
                                >
                                <div>
                                  <input
                                    id="lastname"
                                    type="text"
                                    class="form-control"
                                    v-model="userInfo.lastname"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div role="group" class="form-group">
                                <label for="adress" class="d-block"
                                  >Adresse</label
                                >
                                <div>
                                  <input
                                    id="adress"
                                    type="text"
                                    class="form-control"
                                    v-model="userInfo.address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div role="group" class="form-group">
                                <label for="email" class="d-block"
                                  >Situation matrimoniale</label
                                >
                                <div>
                                  <b-form-select v-model="userInfo.marital_status">
                                    <option value="Célibataire avec enfant">
                                      Célibataire avec enfant
                                    </option>
                                    <option value="Célibataire sans enfant">
                                      Célibataire sans enfant
                                    </option>

                                    <option value="Marié">Marié(e)</option>
                                    <option value="Divorcé">Divorcé(e)</option>
                                    <option value="Veuf/Veuve">
                                      Veuf/Veuve
                                    </option>
                                  </b-form-select>
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-md-4 col-12">
                              <div role="group" class="form-group">
                                <label for="phone" class="d-block"
                                >Téléphone</label
                                >
                                <div>
                                  <input
                                  id="phone"
                                  type="number"
                                  class="form-control"
                                  v-model="userInfo.telephone"
                                  />
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-md-4 col-12" v-if="this.userInfo.role_id === 1">
                              <div role="group" class="form-group">
                              <label for="email" class="d-block"
                                >Type de contrat</label
                              >
                              <div>
                                <b-form-select  v-model="userInfo.contract_id" itemprop="name" >
                                  <b-form-select-option :value="contract.id" v-for="contract in contracts" :key="contract.id">
                                    {{ contract.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </div>
                            </div>
                            </div>
                          </div>

                          <button
                            type="button"
                            class="
                              btn
                              mb-1 mb-sm-0
                              mr-0 mr-sm-1
                              btn-primary 
                            "
                            @click="updateColl"
                          >
                            Mettre à jour</button
                          >
                        </form>
                      </validation-observer>
                    </b-overlay>
                  </b-card-text>
                </b-card>
              </div>
            </div>

            <!---->
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      firstname: "",
      errorMessage: "",
      error: "",
      errors: "",
      errorModal: false,
      imageUrl: null,
    };
  },
  components: {
    ValidationObserver,
  },

  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState('enterprise', ['contracts']),
  },

  mounted() {
    this.showUser(this.$route.params.id);
    this.getContracts();
  },

  methods: {
    ...mapActions('enterprise', ['getContracts']),

    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.userInfo.picture = file;
      this.userInfo.picture_only = 1;
      this.updateColl();
    },
    openFilePicker() {
      document.getElementById("picture").click();
    },
    async updateColl() {
      const valid = await this.$refs.updateCollaborator.validate();
      if (!valid) return;
      this.loading = true;
      this.userInfo.picture_only = 0;
      this.updateUser(this.$formData(this.userInfo))
        .then((r) => {
          this.loading = false;
          this.imageUrl = null;
          this.$router.push({
            name: "member-profile"
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorModal = true;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.errors = error;
        });
    },
    ...mapActions("auth", ["showUser", "updateUser"]),
  },
};
</script>


<style>
@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
}
</style>
